import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import LightGallery from 'lightgallery/react';
import 'lightgallery/css/lightgallery.css';

const Sidebar = ({ data, style, rtl }) => {
  useEffect(() => {
    const lg = document.querySelector('.lg-react-element');
    if (lg) lg.className = 'd-flex justify-content-between flex-wrap';
  }, []);

  return (
    <div className="col-lg-4">
      <div className="side-blog style-5 ps-lg-5 mt-5 mt-lg-0">
        <form action="contact.php" className="search-form mb-50" method="post">
          <h6 className="title mb-20 text-uppercase fw-normal">
            { rtl ? 'بحث' : 'search' }
          </h6>
          <div className="form-group position-relative">
            <input type="text" className="form-control rounded-pill" placeholder={ rtl ? "اكتب وارسل" : "Type and hit enter" } />
            <button className="search-btn border-0 bg-transparent"> <i className="fas fa-search"></i> </button>
          </div>
        </form>

        
        <div className="side-newsletter mb-50">
          <h6 className="title mb-10 text-uppercase fw-normal">
            { rtl ? 'نشرة الاخبار' : 'newsletter' }
          </h6>
          <div className="text">
            {  'Register now to get latest updates on promotions & coupons.' }
          </div>
          <form action="contact.php" className="form-subscribe" method="post">
            <div className="email-input d-flex align-items-center py-3 px-3 bg-white mt-3 radius-5">
              <span className="icon me-2 flex-shrink-0">
                <i className="far fa-envelope"></i>
              </span>
              <input type="text" placeholder="Email Address" className="border-0 bg-transparent fs-12px" />
            </div>
            <button className={`btn bg-blue${style} sm-butn text-white hover-darkBlue w-100 mt-3 radius-5 py-3`}>
              <span>{  'Subscribe' }</span>
            </button>
          </form>
        </div>

        <div className="side-share mb-50">
          <h6 className="title mb-20 text-uppercase fw-normal">
            {  'social' }
          </h6>
          <a href="https://twitter.com/MutualTrustMfb" className="social-icon">
            <i className="fab fa-twitter"></i>
          </a>
          <a href="https://facebook.com/mutualtrustmicrofinancebank" className="social-icon">
            <i className="fab fa-facebook-f"></i>
          </a>
          
          <a href="https://instagram.com/mutualtrustmfbltd" className="social-icon">
            <i className="fab fa-instagram"></i>
          </a>
          <a href="https://Linkedin.com/in/mutualtrustmicrofinancebank" className="social-icon">
            <i className="fab fa-linkedin-in"></i>
          </a>
        </div>

        
        
      </div>
    </div>
  )
}

export default Sidebar