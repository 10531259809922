import React, { useState, useEffect } from "react";
import Blogs from "./Blogs";
import Sidebar from "./Sidebar";
import axios from "axios";


import allNewsData from "data/Blog/all-news.json";

const AllNews = ({ isWide, leftSidebar, style = "4", rtl }) => {
  const data = allNewsData; 
  const [blogList, setBlogList] = useState([]);
  const [loading, setLoading] = useState(false);


  useEffect(() => {
    (async () => {
      setLoading(true);
      const response = await axios
        .get("https://blog.mutualtrustmfb.com/posts/")
        .then((response) => {
          console.log(response)
          const res = setBlogList(response.data);
          console.log(res)
        });

      setLoading(false);
    })();
  }, []);


  return (
    <section className="all-news section-padding blog bg-transparent style-3">
      <div className="container">
        <div
          className={`row ${
            isWide
              ? "justify-content-center"
              : leftSidebar
              ? "gx-5"
              : "gx-4 gx-lg-5"
          }`}
        >
          {!isWide && leftSidebar && (
            <Sidebar data={data.sidebar} style={style} rtl={rtl} />
          )}
          <Blogs blogList={blogList} isWide={isWide} style={style} rtl={rtl} />
          {!isWide && !leftSidebar && (
            <Sidebar data={data.sidebar} style={style} rtl={rtl}z />
          )}
        </div>
      </div>
    </section>
  );
};

export default AllNews;
